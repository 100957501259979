import {FC} from 'react';
import {StyledCarouselArrow} from 'styled/components';
import {IcomoonIcon} from './IcomoonIcon';
import {Theme} from 'styled/Theme';

interface CarouselArrowProps {
  isLeft?: boolean;
}

export const CarouselArrow: FC<CarouselArrowProps> = ({isLeft}) => {
  return (
    <StyledCarouselArrow>
      <IcomoonIcon
        icon={isLeft ? 'arrow-left' : 'arrow-right'}
        size={19}
        color={Theme.textBlack}
      />
    </StyledCarouselArrow>
  );
};
