import {FC} from 'react';
import LogoImg from 'assets/logo.svg';
import {Link, useLocation} from 'react-router-dom';
import {Button} from './Button';
import {BurgerMenu} from './BurgerMenu';
import {StyledHeader} from 'styled/components';
import WhitepaperPDF from 'assets/whitepaper.pdf';


enum Path {
  newTokenListing = '/new-token-listing',
  uniswap = '/uniswap',
  pancakeSwap = '/pancake-swap',
  traderJoe = '/trader-joe',
  spookySwap = '/spooky-swap',
  arbitrage = '/arbitrage',
  insurance = '/insurance',
  cexListings = '/cex-listings',
  roadmap = '/roadmap',
  whitepaper = '/whitepaper',
  about = '/about',
  termsConditions = '/terms-conditions',
  termsOfTokenSaleUse = '/terms-of-token-sale-use',
  financialDisclaimer = '/financial-disclaimer',
  privacyPolicy = '/privacy-policy',
}

export const Header: FC = () => {
  const {pathname} = useLocation();

  const hiddenDesktopMenu =
    pathname === Path.termsConditions ||
    pathname === Path.termsOfTokenSaleUse ||
    pathname === Path.financialDisclaimer ||
    pathname === Path.privacyPolicy;

  return (
    <StyledHeader>
      <div className="header-main-wrapper">
        <Link to="/">
          <img src={LogoImg} alt="logo" />
        </Link>
        <div className="mobile-menu">
          <BurgerMenu />
        </div>
        {!hiddenDesktopMenu && (
          <>
            <ul className="desktop-menu">
              <Link to="/" className="link cexListings">
                <span className="label-link">COOMING SOON</span>
                <li key="dashboard">
              New Token Listing
                </li>
              </Link>
              {/* <li>
            <span className="label-link">AUG 31 ARRIVING</span>
            New Token Listing
            <IcomoonIcon
              icon="chevron-down"
              size={19}
              color={Theme.textBlack}
              className="menu-icon"
            />
            <div className="internal-link">
              <Link to="/uniswap" className="link">
                <li
                  className={pathname === Path.uniswap ? 'active' : ''}
                >
                  Uniswap
                </li>
              </Link>
              <Link to="/pancake-swap" className="link">
                <li
                  className={pathname === Path.pancakeSwap ? 'active' : ''}
                >
                  PancakeSwap
                </li>
              </Link>
              <Link to="/trader-joe" className="link">
                <li
                  className={pathname === Path.traderJoe ? 'active' : ''}
                >
                  TraderJoe
                </li>
              </Link>
              <Link to="/spooky-swap" className="link">
                <li
                  className={pathname === Path.spookySwap ? 'active' : ''}
                >
                  SpookySwap
                </li>
              </Link>
            </div>
          </li> */}
              <Link to="/" className="link arbitrage">
                <span className="label-link">COOMING SOON</span>
                <li
                  key="arbitrage"
                  className={
                    'arbitrage ' +
                (pathname.includes(Path.arbitrage) ? 'active' : '')
                  }
                >
              Arbitrage
                </li>
              </Link>
              <Link to="/" className="link insurance">
                <span className="label-link">COOMING SOON</span>
                <li
                  key="insurance"
                  className={'insurance ' +
              (pathname === Path.insurance ? 'active' : '')}
                >
              Insurance
                </li>
              </Link>
              <Link to="/" className="link cexListings">
                <span className="label-link">COOMING SOON</span>
                <li
                  key="cexListings"
                  className={
                    'cexListings ' +
                (pathname === Path.cexListings ? 'active' : '')
                  }
                >
              CEX Listings
                </li>
              </Link>
              <Link to="/roadmap" className="link">
                <li
                  key="roadmap"
                  className={
                    'roadmap ' +
                (pathname === Path.roadmap ? 'active' : '')
                  }
                >
              Roadmap
                </li>
              </Link>
              <a
                className="link"
                href={WhitepaperPDF}
                target="_blank"
                rel="noreferrer"
              >
                <li>
              Whitepaper
                </li>
              </a>
            </ul>
            <div className="desktop-btn-wrapper">
              <span className="label-link">COOMING SOON</span>
              <Button className="desktop-btn" disabled>Connect Wallet</Button>
            </div>
          </>
        )}
      </div>
    </StyledHeader>
  );
};

export default Header;
