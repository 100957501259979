import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledFaqTab = styled.div`
  width: 650px;

  .faq-btn {
    background-color: unset;
    appearance: none;
    border: none;
    padding: 16px 0;
    border-bottom: 1px solid ${Theme.background};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    & > span {
      font-family: Montserrat-SemiBold;
      color: ${Theme.background};
      font-size: 20px;
      line-height: 150%;
    }

    .faq-icon {
      margin-right: 28px;
    }
  }

  .answer-wrapper {
    & > p {
      color: ${Theme.background};
      font-family: Montserrat-Regular;
      font-size: 20px;
      line-height: 150%;
      padding: 16px 0;
      border-bottom: 1px solid #6894F0;
    }
  }

  @media(max-width: 1400px) {
    width: 412px;

    .faq-btn {
      & > span {
        font-size: 16px;
      }
  
      .faq-icon {
        margin-right: 0px;
      }
    }

    .answer-wrapper {
      & > p {
        font-size: 16px;
      }
    }
  }

  @media(max-width: 760px) {
    width: 100%;

    .faq-btn {
      & > span {
        font-size: 14px;
      }
  
      .faq-icon {
        margin-right: 0px;
      }
    }

    .answer-wrapper {
      & > p {
        font-size: 14px;
      }
    }
  }
`;
