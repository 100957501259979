import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledHomeTableHeader = styled.div`
  border-bottom: 1px solid ${Theme.borderBlue};
  padding: 0 18px;
  .row {  
    padding: 24px 0;
    align-items: center!important;
    justify-content: space-around!important;

    .text-header {
      font-family: Montserrat-SemiBold;
      font-size: 12px;
      color: ${Theme.textBlack};
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
`;
