import {StyledTableMobile} from 'styled/components';
import {FC} from 'react';
import {IPair} from 'models/IPair';
import CopyIcon from 'assets/icons/copy.svg';
import {formattedValue} from 'helpers/formattedValue';
import {formattedDate} from 'helpers/formattedDate';

interface TableMobileProps {
  items?: IPair[];
}

export const TableMobile: FC<TableMobileProps> = ({items}) => {
  return (
    <StyledTableMobile>
      <div className="header">
        <div className="header-col first-col">
          <p className="header-title">Listed,</p>
          <p className="header-title">Token</p>
        </div>
        <div className="header-col third-col">
          <p className="header-title">Market Cap</p>
          <p className="header-title">Current Price</p>
        </div>
        <div className="header-col third-col">
          <p className="header-title">% Change</p>
          <p className="header-title">Liquidity</p>
        </div>
        {/* <div className="header-col sec-col">
          <p className="header-title">Market Cap</p>
          <p className="header-title">Launch Price</p>
        </div>
        <div className="header-col third-col">
          <p className="header-title">Current Price</p>
          <p className="header-title">% Change</p>
        </div>
        <div className="header-col third-col">
          <p className="header-title">Liquidity,</p>
          <p className="header-title">Buy Tax</p>
        </div>
        <div className="header-col last-col">
          <p className="header-title">Sell Tax,</p>
          <p className="header-title">Contract Adress</p>
        </div> */}
      </div>
      {items?.length && (
        items.map((item) => (
          <div className="body" key={item.pairAddress}>
            <div className="body-col first-col">
              <p className="body-text">{formattedDate(item.createdAt)}</p>
              <p className="body-text">
                {item.token0Symbol}/
              </p>
              <p className="body-text text-ellipsis">
                {item.token1Symbol}
              </p>
            </div>
            <div className="body-col third-col">
              <p className="body-text">
                {item.marketCap ? formattedValue(item.marketCap) : 'N/A'}
              </p>
              <p className="body-text">
                {item.currentPrice ? formattedValue(item.currentPrice) : 'N/A'}
              </p>
            </div>
            <div className="body-col third-col">
              <p className="body-text">
                {item.percentChange ?
                  `${item.percentChange.toFixed(2)}%` :
                  'N/A'}
              </p>
              <p className="body-text">
                {item.liquidity ? formattedValue(item.liquidity) : 'N/A'}
              </p>
            </div>
            {/* <div className="body-col sec-col">
              <p className="body-text">
                {item.marketCap ? formattedValue(item.marketCap) : 'N/A'}
              </p>
              <p className="body-text">
                {item.launchPrice ? formattedValue(item.launchPrice) : 'N/A'}
              </p>
            </div> */}
            {/* <div className="body-col third-col">
              <p className="body-text">
                {item.currentPrice ? formattedValue(item.currentPrice) : 'N/A'}
              </p>
              <p className="body-text">
                {item.percentChange ?
                  `${item.percentChange.toFixed(2)}%` :
                  'N/A'}
              </p>
            </div> */}
            {/* <div className="body-col third-col">
              <p className="body-text">
                {item.liquidity ? formattedValue(item.liquidity) : 'N/A'}
              </p>
              <p className="body-text">
                10
              </p>
            </div>
            <div className="body-col last-col">
              <p className="body-text">
                10
              </p>
              <p className="body-text">
                <span className="text-row">0000</span>
                <img src={CopyIcon} className="copy-icon" alt="copy-icon" />
              </p>
            </div> */}
          </div>
        ))
      )}
    </StyledTableMobile>
  );
};
