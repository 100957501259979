import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledDexTab = styled.button`
  background-color: unset;
  color: ${Theme.textBlack};
  font-family: Montserrat-Medium;
  font-size: 18px;
  line-height: 120%;
  border: none;
  padding: 24px 0;
  border-top: 1px solid ${Theme.lightBlue};
  cursor: pointer;
  width: 100%;
  transition: color 0.2s ease-in-out;

  &.active-tab {
    font-family: Montserrat-Bold;
    color: ${Theme.blue};
  }

  &:hover {
    color: ${Theme.blue};
  }
`;
