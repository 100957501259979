/* eslint-disable max-len */
import {FC} from 'react';
import {StyledPolicyPage} from 'styled/components';
import {TermsConditionsData} from '../data/PolicyData';
import {IcomoonIcon, PolicyItem} from 'components';
import {Theme} from 'styled/Theme';
import GraphFigure from 'assets/figure.png';

export const TermsConditions: FC = () => {
  return (
    <StyledPolicyPage>
      <h1 className="title-with-subtitle">Terms & Conditions</h1>
      <span className="updated-text">Last Updated: October  3, 2022.</span>
      <div className="text-wrapper">
        <p className="text">
        Welcome to the Moonwalk platform (the “Platform”). The Platform is operated by Vision Inc. [(“Moonwalk”)] [1] and is accessible through Moonwalk’s websites, smartphone applications, tablet applications, and other network-connected products and services. The following terms and conditions (“Terms”) govern your use of the Platform.
  By accessing, viewing, or using the content, material, products, or services available on or through the Platform (collectively, the " Services"),
  If you have any questions concerning these Terms or wish to exercise your rights as described below, please email Moonwalk at: <a href = "mailto: rob@Moonwalk.financial">rob@Moonwalk.financial</a>.
        </p>
      </div>
      {TermsConditionsData.map((item, i) => {
        return (
          <PolicyItem title={item.title} text={item.text} index={i} key={item.title}/>
        );
      })}
      <IcomoonIcon
        icon="dots"
        size={182}
        color={Theme.lightBlue}
        className="icon-dots top-right-dots"
      />
      <IcomoonIcon
        icon="dots"
        size={182}
        color={Theme.lightBlue}
        className="icon-dots middle-right-dots"
      />
      <IcomoonIcon
        icon="dots"
        size={255}
        color={Theme.lightBlue}
        className="icon-dots bottom-right-dots"
      />
      <img src={GraphFigure} alt="graph-figure" className="icon-dots graph-figure-right" />
      <img src={GraphFigure} alt="graph-figure" className="icon-dots graph-figure-left" />
    </StyledPolicyPage>
  );
};

export default TermsConditions;
