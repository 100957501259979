import styled from 'styled-components';
import {Theme} from 'styled/Theme';

import Pancakeswap from 'assets/icons/pancakeswap-big.svg';
import Uniswap from 'assets/icons/uniswap-big.svg';
import TraderJoe from 'assets/icons/traderjoe-big.svg';
import SpookySwap from 'assets/icons/spookyswap-big.svg';

export const StyledHomeDexChoosing = styled.div`
  max-width: 432px;
  width: 100%;
  box-shadow: 0px 10px 25px rgba(38, 101, 235, 0.15);
  border-radius: 50px;
  position: relative;
  background: ${Theme.background};
  height: fit-content;
  margin-top: 38px;

  .image-wrapper {
    height: 260px;
    width: 100%;
    background: ${Theme.lightBlue};
    border-radius: 50px 50px 0px 0px;

    &.PancakeSwap {
      background-image: url(${Pancakeswap});
    }
    &.UniSwap {
      background-image: url(${Uniswap});
    }
    &.TraderJoe {
      background-image: url(${TraderJoe});
    }
    &.SpookySwap {
      background-image: url(${SpookySwap});
    }
  }

  .graph-figure-small {
    position: absolute;
    bottom: -150px;
    right: -120px;
    z-index: -1;
    width: 350px;
  }

  @media(max-width: 1400px) {
    margin-top: 160px;

    .graph-figure-small {
      bottom: -81px;
      right: -165px;
    }
  }

  @media(max-width: 760px) {
    margin-top: 92px;
    .graph-figure-small {
      bottom: -73px;
      right: -85px;
    }
  }
`;
