import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledHomeTableItem = styled.div`
  border-bottom: 1px solid ${Theme.lightBlue};
  margin: 0 18px;
  .row {  
    padding: 15px 0;
    align-items: center!important;
    justify-content: space-around!important;

    .text-row {
      font-family: Montserrat-Regular;
      font-size: 12px;
      color: ${Theme.textBlack};
      margin-bottom: 0;

      &.positive {
        color: ${Theme.green};
      }
      &.negative {
        color: ${Theme.red};
      }
    }

    .text-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
