import {Header} from './Header';
import {Button} from './Button';
import {IcomoonIcon} from './IcomoonIcon';
import {CarouselArrow} from './CarouselArrow';
import {CarouselItem} from './CarouselItem';
import {Home} from './Home';
import {HomeTile} from './HomeTile';
import {HomeTool} from './HomeTool';
import {HomeDexChoosing} from './HomeDexChoosing';
import {Faq} from './Faq';
import {Footer} from './Footer';
import {BurgerMenu} from './BurgerMenu';
import {HomeTable} from './HomeTable';
import {HomeTableItem} from './HomeTableItem';
import {TableMobile} from './TableMobile';
import {HomeTableHeader} from './HomeTableHeader';
import {Roadmap} from './Roadmap';
import {FinancialDisclaimer} from './FinancialDisclaimer';
import {PolicyItem} from './PolicyItem';
import {TermsConditions} from './TermsConditions';
import {TermsOfTokenSaleUse} from './TermsOfTokenSaleUse';
import {PrivacyPolicy} from './PrivacyPolicy';

export {
  Header,
  Button,
  IcomoonIcon,
  CarouselArrow,
  CarouselItem,
  Home,
  HomeTile,
  HomeTool,
  HomeDexChoosing,
  Faq,
  Footer,
  BurgerMenu,
  HomeTable,
  HomeTableItem,
  TableMobile,
  HomeTableHeader,
  Roadmap,
  FinancialDisclaimer,
  PolicyItem,
  TermsConditions,
  TermsOfTokenSaleUse,
  PrivacyPolicy,
};
