/* eslint-disable max-len */
import {FC} from 'react';
import {StyledRoadmap} from 'styled/components';
import {LaunchData, AcceptanceData, GrowthData} from '../data/RoadmapData';
import {IcomoonIcon} from './IcomoonIcon';
import {Theme} from 'styled/Theme';
import GraphFigure from 'assets/figure.png';

export const Roadmap: FC = () => {
  return (
    <StyledRoadmap>
      <h1>Roadmap</h1>
      <div className="image-wrapper"></div>
      <p className="info-text">MoonWalk started in August 2022 with a successful launch.
        <br/>The roadmap is prudently planned and shows a clear growth story.
      </p>
      <h2>Focus in 2022</h2>
      <div className="focus-wrapper">
        <IcomoonIcon
          icon="dots"
          size={182}
          color={Theme.grey}
          className="icon-dots icon-dots-right"
        />
        <IcomoonIcon
          icon="dots"
          size={182}
          color={Theme.lightBlue}
          className="icon-dots icon-dots-left"
        />
        <img src={GraphFigure} alt="graph-figure" className="icon-dots graph-figure" />
        <div className="section-wrapper">
          <div></div>
          <div className="side-wrapper">
            <h3>Launch</h3>
            <div className="section-items-wrapper">
              <div>
                {LaunchData.slice(0, 3).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
              <div>
                {LaunchData.slice(3, 6).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper section-wrapper-second">
          <div className="side-wrapper">
            <h3>Acceptance</h3>
            <div className="section-items-wrapper">
              <div>
                {AcceptanceData.slice(0, 3).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
              <div>
                {AcceptanceData.slice(3, 6).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="section-wrapper">
          <div></div>
          <div className="side-wrapper">
            <h3>Growth</h3>
            <div className="section-items-wrapper">
              <div>
                {GrowthData.slice(0, 3).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
              <div>
                {GrowthData.slice(3, 6).map((item) => {
                  return (
                    <div className="check-wrapper" key={item.title}>
                      <div className="icon-wrapper">
                        <IcomoonIcon
                          icon="check"
                          size={20}
                          color={Theme.blue}
                          className="icon-check"
                        />
                      </div>
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Focus in 2023</h2>
      <p className="info-text">Our goal is to become a leading provider of essential intelligence with innovative solutions for DeFi.
        <br className="info-text-divider"/> Our growth ambitions is based on our vision and supported by our optimistic community.
        <br className="info-text-divider"/> As our project grows in value in the next years, we will update on product plans and their timeline.</p>
      <IcomoonIcon
        icon="dots"
        size={182}
        color={Theme.lightBlue}
        className="icon-dots icon-dots-bottom"
      />
    </StyledRoadmap>
  );
};

export default Roadmap;
