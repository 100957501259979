import {configureStore} from '@reduxjs/toolkit';
import {uniswapAPI} from 'services/uniswap';
import {pancakeswapAPI} from 'services/pancakeswap';
import {spookyswapAPI} from 'services/spookyswap';
import {traderjoeAPI} from 'services/traderjoe';

export const store = configureStore({
  reducer: {
    [uniswapAPI.reducerPath]: uniswapAPI.reducer,
    [pancakeswapAPI.reducerPath]: pancakeswapAPI.reducer,
    [spookyswapAPI.reducerPath]: spookyswapAPI.reducer,
    [traderjoeAPI.reducerPath]: traderjoeAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, etc.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
        .concat(uniswapAPI.middleware)
        .concat(pancakeswapAPI.middleware)
        .concat(spookyswapAPI.middleware)
        .concat(traderjoeAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
