import IcomoonReact from 'icomoon-react';
import {FC} from 'react';
import iconSet from 'selection.json';

interface IcomoonIconProps {
  icon: string;
  size: number;
  color: string;
  className?: string;
}

export const IcomoonIcon: FC<IcomoonIconProps> = ({
  icon,
  size,
  color,
  className,
}) => {
  return (
    <IcomoonReact
      className={className}
      icon={icon}
      iconSet={iconSet}
      color={color}
      size={size}
    />
  );
};
