import {IPair} from 'models/IPair';
import {FC} from 'react';
import {Col, Row} from 'react-grid-system';
import {StyledHomeTableItem} from 'styled/components';
import {formattedValue} from 'helpers/formattedValue';
import {formattedDate} from 'helpers/formattedDate';

interface TableItemProps {
  item: IPair;
}

export const HomeTableItem: FC<TableItemProps> = ({item}) => {
  return (
    <StyledHomeTableItem>
      <Row className="row">
        <Col md={1} className="col">
          <p className="text-row">
            {item.createdAt ? formattedDate(item.createdAt) : 'N/A'}
          </p>
        </Col>
        <Col md={3} className="col">
          <p className="text-row text-ellipsis">
            {item.token0Symbol}/{item.token1Symbol}
          </p>
        </Col>
        <Col md={2} className="col">
          <p className="text-row">
            {item.marketCap ? formattedValue(item.marketCap) : 'N/A'}
          </p>
        </Col>
        {/* <Col md={1} className="col">
          <p className="text-row">
            {item.launchPrice ? formattedValue(item.launchPrice) : 'N/A'}
          </p>
        </Col> */}
        <Col md={2} className="col">
          <p className="text-row">
            {item.currentPrice ? formattedValue(item.currentPrice) : 'N/A'}
          </p>
        </Col>
        <Col md={2} className="col">
          <p className={`text-row ${
            item.percentChange && item.percentChange > 0 ?
              'positive' :
              item.percentChange && item.percentChange < 0 ?
              'negative' : ''}
          `}>
            {item.percentChange ? `${item.percentChange.toFixed(2)}%` : 'N/A'}
          </p>
        </Col>
        <Col md={2} className="col">
          <p className="text-row">
            {item.liquidity ? formattedValue(item.liquidity) : 'N/A'}
          </p>
        </Col>
        {/* <Col md={1} className="col">
          <p className="text-row">10</p>
        </Col> */}
        {/* <Col md={1} className="col">
          <p className="text-row">10</p>
        </Col> */}
      </Row>
    </StyledHomeTableItem>
  );
};
