/* eslint-disable max-len */
import {FC} from 'react';
import {StyledHome} from 'styled/components';
import {Button, HomeTile, HomeTool} from 'components';
import {IcomoonIcon} from './IcomoonIcon';
import {Theme} from 'styled/Theme';
import GraphInfo from 'assets/graph-info.png';
import GraphFigure from 'assets/figure.png';
import TwitterInfo from 'assets/twitter-info.png';
import {HomeDex} from './HomeDex';
import {TilesData, graphData, ToolsData} from '../data/HomeData';
import WhitepaperPDF from 'assets/whitepaper.pdf';

export const Home: FC = () => {
  return (
    <StyledHome>
      <h1>Guiding your through <br className="header-divider"/><span>Crypto’s Most Valuable</span> Data</h1>
      <p>Find new token listings, discovery arbitrage opportunities,
        and protect your investments.</p>
      <div className="top-wrapper">
        <div className="tiles-wrapper">
          {TilesData.map((item) => {
            return (
              <HomeTile
                key={item.iconName}
                title={item.title}
                iconName={item.iconName}
                comingSoon={item.comingSoon}
                arringSoon={item.arringSoon}
              />
            );
          })}
        </div>
        <div className="large-tile">
          <div className="large-tile-header">
            <div className="icon-wrapper"></div>
            <p>Powered by the $MWK token</p>
          </div>
          <div className="large-tile-body">
            <h3>Making DeFi Data Friendly & Accessible</h3>
            <p>The $MWK token will unlock access to the industry’s most
              valuable and important data for investment decisions.</p>
            <p>We want to be “Bloomberg for Crypto”.</p>
            <div className="btn-wrapper">
              <a
                className="btn-link large-btn"
                href={WhitepaperPDF}
                target="_blank"
                rel="noreferrer"
              >
                Read Whitepaper
              </a>
              <Button flat disabled>Buy $MWK</Button>
            </div>
          </div>
          <IcomoonIcon
            icon="dots"
            size={182}
            color={Theme.grey}
            className="icon-dots-right"
          />
        </div>
        <IcomoonIcon
          icon="dots"
          size={182}
          color={Theme.lightBlue}
          className="icon-dots-left"
        />
      </div>
      <div className="graph-wrapper">
        <div className="graph-text-block">
          <p className="graph-text-title">JOIN OUR COMMUNITY</p>
          <h4>Empower your crypto journey</h4>
          <p>Unlock access to our industry-leading real-time
            dataset on token prices and products.</p>
          {graphData.map((item) => {
            return (
              <div className="check-wrapper" key={item.text}>
                <div className="icon-wrapper">
                  <IcomoonIcon
                    icon="check"
                    size={20}
                    color={Theme.blue}
                    className="icon-check"
                  />
                </div>
                <p>{item.text}</p>
              </div>
            );
          })}
          <span className="graph-text-info">*While we continue building our
            suite of DeFi products,
            our DEX tracker will be free to use. Once our arbitrage
            product is complete, the $MWK will unlock our entire
            platform and DeFi’s largest data hub.</span>
          <Button disabled>Buy $MWK</Button>
        </div>
        <div className="graph-img-wrapper">
          <img src={GraphInfo} alt="graph" />
          <img src={GraphFigure} alt="graph-figure" className="graph-figure" />
        </div>
      </div>
      <div className="graph-wrapper">
        <div className="twitter-wrapper">
          <img src={TwitterInfo} alt="twitters" />
          <IcomoonIcon
            icon="dots"
            size={255}
            color={Theme.lightBlue}
            className="icon-dots-left-large"
          />
        </div>
        <div className="graph-text-block twitter-text-block">
          <p className="graph-text-title">JOIN OUR COMMUNITY</p>
          <h4>Tools to Find Generational Wealth</h4>
          <div className="tools-wrapper-mobile">
            <div className="tools-wrapper">
              <div>
                {ToolsData.slice(0, 5).map((item) => {
                  return (
                    <HomeTool
                      key={item.iconName}
                      title={item.title}
                      iconName={item.iconName}
                      comingSoon={item.comingSoon}
                      url={item.url}
                    />
                  );
                })}
              </div>
              <div>
                {ToolsData.slice(5, 10).map((item) => {
                  return (
                    <HomeTool
                      key={item.iconName}
                      title={item.title}
                      iconName={item.iconName}
                      comingSoon={item.comingSoon}
                      url={item.url}
                    />
                  );
                })}
              </div>
            </div>
            <Button disabled>Buy $MWK</Button>
          </div>
        </div>
      </div>
      <HomeDex />
    </StyledHome>
  );
};

export default Home;
