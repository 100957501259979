import {FC} from 'react';
import ShowMoreText from 'react-show-more-text';
import {StyledPolicyItem} from 'styled/components';

interface PolicyItemProps {
  title: string;
  text: string[];
  index: number;
  sublist?: boolean;
  alphabetically?: boolean;
}

export const PolicyItem: FC<PolicyItemProps> = ({
  title,
  text,
  index,
  sublist,
  alphabetically,
}) => {
  return (
    <StyledPolicyItem>
      <h3 className={sublist ? 'sublist-title' : ''}>
        {alphabetically ? title : `${index + 1}. ${title}`}
      </h3>
      <ShowMoreText
        lines={3}
        more="Show more"
        less="...Show less"
        className={
          sublist ?
            'sublist-content policy-content' :
            'policy-content'}
        anchorClass={
          sublist ?
            'sublist-content-anchor policy-content-anchor' :
            'policy-content-anchor'}
        expanded={false}
      >
        {text.map((p) => {
          return (
            <p>{p}</p>
          );
        })}
      </ShowMoreText>
    </StyledPolicyItem>
  );
};
