import styled from 'styled-components';
import {Theme} from 'styled/Theme';
import Pancakeswap from 'assets/icons/pancakeswap.svg';
import TraderJoe from 'assets/icons/traderjoe.svg';
import UniswapV2 from 'assets/icons/uniswapv2.svg';
import Pangolin from 'assets/icons/pangolin.svg';
import SushiSwap from 'assets/icons/sushiswap.svg';

export const StyledCarouselItem = styled.div`
  width: 160px;
  height: 48px;
  background-color: ${Theme.lightBlue};
  border-radius: 10px;
  border: 1px solid ${Theme.borderBlue};
  box-shadow: 0px 4px 10px rgba(38, 101, 235, 0.07);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  .icon-image {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;

    &.pancakeswap {
      background-image: url(${Pancakeswap});
    }
    &.traderjoe {
      background-image: url(${TraderJoe});
    }
    &.uniswapv2 {
      background-image: url(${UniswapV2});
    }
    &.pangolin {
      background-image: url(${Pangolin});
    }
    &.sushiswap {
      background-image: url(${SushiSwap});
    }
  }

  span {
    font-family: Montserrat-Medium;
    font-size: 14px;
    color: ${Theme.textBlack};
    transition: color 0.2s ease-in-out;
  }

  :hover {
    background-color: ${Theme.blue};
    > span {
      color: #fff;
    }
  }
`;
