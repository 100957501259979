import {FC} from 'react';
import {StyledCarouselItem} from '../styled/components';
import {IcomoonIcon} from './IcomoonIcon';

interface CarouselItemProps {
  title: string;
  iconName?: string;
}

export const CarouselItem: FC<CarouselItemProps> = ({title, iconName}) => {
  return (
    <StyledCarouselItem>
      {iconName ? (
        <div className={'icon-image ' + iconName}></div>
      ) : (
        <IcomoonIcon
          icon='star'
          size={22}
          color='#FBBC5D'
          className='icon-image'
        />
      )}
      <span>{title}</span>
    </StyledCarouselItem>
  );
};
