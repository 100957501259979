import styled, {createGlobalStyle} from 'styled-components';
import MontserratRegular from '../assets/fonts/Montserrat-Regular.ttf';
import MontserratRegularItalic from '../assets/fonts/Montserrat-Italic.ttf';
import MontserratMedium from '../assets/fonts/Montserrat-Medium.ttf';
import MontserratSemiBold from '../assets/fonts/Montserrat-SemiBold.ttf';
import MontserratBold from '../assets/fonts/Montserrat-Bold.ttf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import 'normalize.css';
// import "@szhsin/react-menu/dist/index.css";
// import "@szhsin/react-menu/dist/transitions/slide.css";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Montserrat-Regular;
    src: url(${MontserratRegular}) format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: Montserrat-Italic;
    src: url(${MontserratRegularItalic}) format("truetype");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: Montserrat-Medium;
    src: url(${MontserratMedium}) format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: Montserrat-SemiBold;
    src: url(${MontserratSemiBold}) format("truetype");
    font-weight: 600;
  }

  @font-face {
    font-family: Montserrat-Bold;
    src: url(${MontserratBold}) format("truetype");
    font-weight: 700;
  }

  @font-face {
    font-family: Roboto-Regular;
    src: url(${RobotoRegular}) format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto-Medium;
    src: url(${RobotoMedium}) format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: Roboto-Bold;
    src: url(${RobotoBold}) format("truetype");
    font-weight: 700;
  }
  
  html, body, #root {
    min-height: 100%;
  }
  
  body {
    margin: 0;
    background-color: ${({theme}) => theme.background};
    font-family: Montserrat-Regular;
    font-weight: 400;
    box-sizing: border-box;

    &.menu-is-open {
      overflow: hidden;
    }
  }

  p, h1, h2, h3, h4, h5, span {
    margin: 0;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
