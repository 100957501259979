/* eslint-disable max-len */
import {FC} from 'react';
import {StyledFooter} from 'styled/components';
import LogoImg from 'assets/logo-white.svg';
import {Link} from 'react-router-dom';
import {Theme} from 'styled/Theme';
import {IcomoonIcon} from './IcomoonIcon';
import WhitepaperPDF from 'assets/whitepaper.pdf';

export const Footer: FC = () => {
  return (
    <StyledFooter>
      <div className="footer-main-wrapper">
        <div className="logo-wrapper">
          <Link to="/">
            <img src={LogoImg} alt="logo" />
          </Link>
          <div className="icons-wrapper icons-wrapper-mobile">
            <a target="_blank" href='https://t.me/moonwalkfinancial' className="icon-link">
              <IcomoonIcon
                icon="telegram"
                size={24}
                color={Theme.background}
              />
            </a>
            <Link to="/" className="icon-link">
              <IcomoonIcon
                icon="twitter"
                size={24}
                color={Theme.background}
              />
            </Link>
          </div>
        </div>
        <div className="footer-column">
          <h5>New DEX Listing</h5>
          <ul>
            <li>
              <Link to="/" className="link">
                Uniswap V2
              </Link>
            </li>
            <li>
              <Link to="/" className="link">
                PancakeSwap
              </Link>
            </li>
            <li>
              <Link to="/" className="link">
                TraderJoe
              </Link>
            </li>
            <li>
              <Link to="/" className="link">
                SpookySwap
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5>Resources</h5>
          <ul>
            <li>
              <Link to="/" className="link">
                Arbitrage
              </Link>
            </li>
            <li>
              <Link to="/" className="link">
                Insurance
              </Link>
            </li>
            <li>
              <Link to="/" className="link">
                CEX Listing
              </Link>
            </li>
            <li>
              <Link to="/roadmap" className="link">
                Roadmap
              </Link>
            </li>
            <li>
              <a
                className="link"
                href={WhitepaperPDF}
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5>Follow Us</h5>
          <div className="icons-wrapper">
            <a target="_blank" href='https://t.me/moonwalkfinancial' className="icon-link">
              <IcomoonIcon
                icon="telegram"
                size={24}
                color={Theme.background}
              />
            </a>
            <Link to="/" className="icon-link">
              <IcomoonIcon
                icon="twitter"
                size={24}
                color={Theme.background}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-info">
        <div className="footer-info-section">
          <h6>FOR INFORMATIONAL PURPOSES ONLY</h6>
          <p>Moonwalk, Inc. (“Moonwalk”) and its members, officers, directors, owners, employees, agents, representatives, suppliers and service providers (collectively “Moonwalk”) provide this website (the “Site”) for informational purposes only. Use of and access to the Site and the information, materials, services, and other content available on or through the Site (“Content”) are subject to our terms of use and all applicable laws.</p>
        </div>
        <div className="footer-info-section">
          <h6>NO INVESTMENT ADVICE</h6>
          <p>The Content is for informational purposes only, you should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained on our Site constitutes a solicitation, recommendation, endorsement, or offer by Moonwalk or any third party service provider to buy or sell any securities or other financial instruments in this or in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction.</p>
        </div>
        <div className="footer-link-wrapper">
          <Link to="/financial-disclaimer" className="info-link">
            Read the complete Financial Disclaimer here
          </Link>
        </div>
        <p className="terms-text">
          <Link to="/privacy-policy" className="link">
            Privacy&Policy
          </Link>
          <span> | </span>
          <Link to="/terms-conditions" className="link">
            Terms & Conditions
          </Link>
          <span className="terms-divider"> | </span>
          <br className="mobile-next-line"/>
          <Link to="/terms-of-token-sale-use" className="link">
            Terms of Token Sale & Use
          </Link>
        </p>
      </div>
    </StyledFooter>
  );
};

export default Footer;
