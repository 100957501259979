import {StyledButton} from './Button';
import {StyledCarouselArrow} from './CarouselArrow';
import {StyledCarouselItem} from './CarouselItem';
import {StyledTokenCarousel} from './TokenCarousel';
import {StyledHome} from './Home';
import {StyledHomeTile} from './HomeTile';
import {StyledHomeTool} from './HomeTool';
import {StyledHomeDex} from './HomeDex';
import {StyledHomeDexChoosing} from './HomeDexChoosing';
import {StyledDexTab} from './DexTab';
import {StyledFaq} from './Faq';
import {StyledFaqTab} from './FaqTab';
import {StyledFooter} from './Footer';
import {StyledHeader} from './Header';
import {StyledHomeTable} from './HomeTable';
import {StyledHomeTableItem} from './HomeTableItem';
import {StyledTableMobile} from './TableMobile';
import {StyledHomeTableHeader} from './HomeTableHeader';
import {StyledRoadmap} from './Roadmap';
import {StyledPolicyPage} from './PolicyPage';
import {StyledPolicyItem} from './PolicyItem';

export {
  StyledButton,
  StyledCarouselArrow,
  StyledCarouselItem,
  StyledTokenCarousel,
  StyledHome,
  StyledHomeTile,
  StyledHomeTool,
  StyledHomeDex,
  StyledHomeDexChoosing,
  StyledDexTab,
  StyledFaq,
  StyledFaqTab,
  StyledFooter,
  StyledHeader,
  StyledHomeTable,
  StyledHomeTableItem,
  StyledTableMobile,
  StyledHomeTableHeader,
  StyledRoadmap,
  StyledPolicyPage,
  StyledPolicyItem,
};
