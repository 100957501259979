/* eslint-disable max-len */
import {StyledFaq} from 'styled/components';
import {FC} from 'react';
import Visor from 'assets/visor.png';
import {FaqTab} from './FaqTab';

export const Faq: FC = () => {
  const FaqList = [
    {
      title: 'What is Moonwalk?',
      faqs: [
        {
          question: 'Are the tokens displayed in real time?',
          answer: 'I have looked through this FAQ and read the blog posts.',
        },
      ],
    },
    {
      title: 'Technical issues',
      faqs: [
        {
          question: 'Are the tokens displayed in real time?',
          answer: 'I have looked through this FAQ and read the blog posts.',
        },
      ],
    },
    {
      title: 'General',
      faqs: [
        {
          question: 'Are the tokens displayed in real time?',
          answer: 'I have looked through this FAQ and read the blog posts, but I still have a question.',
        },
      ],
    },
    {
      title: 'Trading',
      faqs: [
        {
          question: 'Are the tokens displayed in real time?',
          answer: 'I have looked through this FAQ and read the blog posts.',
        },
      ],
    },
  ];
  return (
    <StyledFaq>
      <h2>FAQ</h2>
      <div className="main-wrapper">
        <div className="img-wrapper">
          <img src={Visor} alt="visor" />
        </div>
        <div className="faq-wrapper">
          {FaqList.map((faq) => {
            return (
              <FaqTab title={faq.title} faqs={faq.faqs} key={faq.title} />
            );
          })}
        </div>
      </div>
    </StyledFaq>
  );
};
