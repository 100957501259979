import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledCarouselArrow = styled.div`
  width: 41px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${Theme.borderBlue};
  box-shadow: 0px 4px 10px rgba(38, 101, 235, 0.07);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  :hover {
    background-color: ${Theme.lightBlue};
  }
`;
