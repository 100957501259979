import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledPolicyPage = styled.div`
  padding: 80px 80px 104px;
  text-align: center;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  .icon-dots {
    position: absolute;
    z-index: -1;
    display: inline-block;
  }

  .top-right-dots {
    top: 8%;
    right: -6%;
  }

  .middle-right-dots {
    top: 50%;
    right: -4%;
  }

  .bottom-right-dots {
    top: 87%;
    right: -10%;
  }

  .graph-figure-right {
    width: 350px;
    top: 28%;
    right: -16%;
  }

  .graph-figure-left {
    width: 350px;
    top: 77%;
    left: -16%;
  }

  h1 {
    font-family: Montserrat-Bold;
    font-size: 46px;
    line-height: 118%;
    color: ${Theme.textBlack};
    margin-bottom: 56px;

    &.title-with-subtitle {
      margin-bottom: 16px;
    }
  }

  .sublist-main-title {
    text-align: left;
    font-family: Montserrat-SemiBold;
    color: ${Theme.textBlack};
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 20px;
  }

  .updated-text {
    font-family: Montserrat-Regular;
    font-size: 20px;
    line-height: 150%;
    color: ${Theme.lightGrey};
    margin-bottom: 56px;
    display: inline-block;
  }

  .text-wrapper {
    margin-bottom: 46px;
  }

  .text {
    font-family: Montserrat-Regular;
    font-size: 20px;
    line-height: 150%;
    color: ${Theme.textBlack};
    text-align: left;

    a {
      font-family: Montserrat-Regular;
      font-size: 20px;
      line-height: 150%;
      color: ${Theme.textBlack};
    }
  }

  .point-wrapper {
    h4 {
      text-align: left;
      font-family: Montserrat-SemiBold;
      color: ${Theme.textBlack};
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 20px;
      margin-top: 40px;
      text-transform: uppercase;
    }
    
    h6 {
      text-align: left;
      font-family: Montserrat-SemiBold;
      color: ${Theme.textBlack};
      font-size: 22px;
      line-height: 120%;
      margin-bottom: 12px;
    }

    .policy-content {
      font-family: Montserrat-Regular;
      font-size: 22px;
      line-height: 150%;
      color: ${Theme.textBlack};
      text-align: left;

      a {
        font-family: Montserrat-Regular;
        font-size: 22px;
        line-height: 150%;
        color: ${Theme.blue};
      }
      
      .policy-content-text {
        font-family: Montserrat-Regular;
        font-size: 22px;
        line-height: 150%;
        color: ${Theme.textBlack};
        text-align: left;
        margin-bottom: 10px;

        span {
          font-weight: bold;
        }

        a {
          font-family: Montserrat-Regular;
          font-size: 22px;
          line-height: 150%;
          color: ${Theme.blue};
        }
      }
    }
  }

  @media(max-width: 1600px) {
    overflow: hidden;
  }

  @media(max-width: 1400px) {
    padding: 56px 32px 24px;
  }

  @media(max-width: 1260px) {
    h1 {
      font-size: 30px;
      &.title-with-subtitle {
        margin-bottom: 14px;
      }
    }
    .updated-text {
      margin-bottom: 48px;
    }

    .graph-figure-left {
      display: none;
    }

    .graph-figure-right {
      right: -20%;
    }
  }
  @media(max-width: 1024px) {
    .bottom-right-dots {
      display: none!important;
    }

    .graph-figure-right {
      right: -25%;
    }

    .text {
      font-size: 18px;

      a {
        font-size: 18px;
      }
    }

    .point-wrapper {
      h4 {
        font-size: 24px;
        margin-bottom: 18px;
        margin-top: 32px;
      }
      
      h6 {
        font-size: 20px;
      }
  
      .policy-content {
        font-size: 18px;
  
        a {
          font-size: 18px;
        }
        
        .policy-content-text {
          font-size: 18px;
  
          a {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media(max-width: 760px) {
    padding: 40px 24px 0;

    .top-right-dots {
      right: -36%;
    }

    .middle-right-dots {
      right: -39%;
    }

    .bottom-right-dots {
      display: none!important;
    }

    .graph-figure-right {
      right: -33%;
    }

    h1 {
      font-size: 22px;
      line-height: 150%;
      &.title-with-subtitle {
        margin-bottom: 12px;
      }
    }

    .updated-text {
      font-size: 16px;
      margin-bottom: 40px;
    }

    .text {
      font-size: 14px;
  
      a {
        font-size: 14px;
      }
    }

    .point-wrapper {
      h4 {
        font-size: 20px;
        margin-bottom: 14px;
        margin-top: 24px;
      }
      
      h6 {
        font-size: 16px;
        margin-bottom: 8px;
      }
  
      .policy-content {
        font-size: 14px;
  
        a {
          font-size: 14px;
        }
        
        .policy-content-text {
          font-size: 14px;
  
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
