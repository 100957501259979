/* eslint-disable max-len */
import {FC} from 'react';
import {StyledPolicyPage} from 'styled/components';
import {TermsOfTokenSaleUseData, RiskDisclosuresData} from '../data/PolicyData';
import {IcomoonIcon, PolicyItem} from 'components';
import {Theme} from 'styled/Theme';
import GraphFigure from 'assets/figure.png';

export const TermsOfTokenSaleUse: FC = () => {
  return (
    <StyledPolicyPage>
      <h1>Terms of Token Sale & Use</h1>
      <div className="text-wrapper">
        <p className="text">PLEASE READ THESE TERMS OF TOKEN SALE CAREFULLY.  NOTE THAT SECTION 15 CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER, WHICH MAY AFFECT YOUR LEGAL RIGHTS. IF YOU DO NOT AGREE TO THESE TERMS OF SALE, DO NOT PURCHASE TOKENS.
        </p>
        <p className="text">Your purchase of MWK Tokens (collectively, “MWK” or “Tokens”) during the Token sale (the “Token Sale”) period (the “Sale Period”) from Moonwalk Financial Inc. (“Company,” “we,” or “us”) is subject to these Terms of Sale (“Terms”).  Each of you and the Company is a “Party,” and together the “Parties.”</p>
        <p className="text">By purchasing Tokens from us during the Sale Period and/or using MWK in connection with the Platform (as defined below), you will be bound by these Terms and all terms incorporated by reference. If you have any questions regarding these terms, please contact us at <a href = "mailto: rob@moonwalk.financial">rob@moonwalk.financial</a>.</p>
        <p className="text">You and the Company agree as follows:</p>
      </div>
      {TermsOfTokenSaleUseData.map((item, i) => {
        return (
          <PolicyItem title={item.title} text={item.text} index={i} key={item.title}/>
        );
      })}
      <h3 className="sublist-main-title">SCHEDULE 1 – RISK DISCLOSURES</h3>
      {RiskDisclosuresData.map((item, i) => {
        return (
          <PolicyItem title={item.title} text={item.text} index={i} key={item.title} sublist />
        );
      })}
      <IcomoonIcon
        icon="dots"
        size={182}
        color={Theme.lightBlue}
        className="icon-dots top-right-dots"
      />
      <IcomoonIcon
        icon="dots"
        size={182}
        color={Theme.lightBlue}
        className="icon-dots middle-right-dots"
      />
      <IcomoonIcon
        icon="dots"
        size={255}
        color={Theme.lightBlue}
        className="icon-dots bottom-right-dots"
      />
      <img src={GraphFigure} alt="graph-figure" className="icon-dots graph-figure-right" />
      <img src={GraphFigure} alt="graph-figure" className="icon-dots graph-figure-left" />
    </StyledPolicyPage>
  );
};

export default TermsOfTokenSaleUse;
