import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledPolicyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;

  h3 {
    text-align: left;
    font-family: Montserrat-SemiBold;
    color: ${Theme.textBlack};
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 20px;

    &.sublist-title {
      font-size: 22px;
      margin-bottom: 12px;
    }
  }

  .policy-content {
    text-align: left;
    font-family: Montserrat-Regular;
    color: ${Theme.textBlack};
    font-size: 22px;
    line-height: 150%;
    p {
      margin-bottom: 20px;
    }

    p:last-child {
      margin-bottom: 0px;
    }
  }

  .policy-content-anchor {
    font-family: Montserrat-Regular;
    font-size: 22px;
    line-height: 150%;
    color: ${Theme.blue};
  }

  @media(max-width: 1200px) {
    margin-bottom: 32px;

    h3 {
      font-size: 24px;
      margin-bottom: 18px;
    }
  
    .policy-content {
      font-size: 18px;
    }
  
    .policy-content-anchor {
      font-size: 18px;
    }
  }

  @media(max-width: 1024px) {
    h3 {
      &.sublist-title {
        font-size: 20px;
        margin-bottom: 12px;
      }
    }
  }

  @media(max-width: 760px) {
    margin-bottom: 24px;

    h3 {
      font-size: 20px;
      margin-bottom: 14px;
    }
  
    .policy-content {
      font-size: 14px;
    }
  
    .policy-content-anchor {
      font-size: 14px;
    }

    h3 {
      &.sublist-title {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
`;
