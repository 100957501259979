import {StyledButton} from '../styled/components';
import {FC, HTMLProps} from 'react';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: () => void;
  flat?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const Button: FC<ButtonProps> = ({
  className,
  disabled,
  children,
  leftIcon,
  rightIcon,
  flat,
  onClick,
  type,
}) => {
  return (
    <StyledButton
      type={type}
      className={className}
      disabled={disabled}
      flat={flat}
      onClick={onClick}
    >
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </StyledButton>
  );
};
