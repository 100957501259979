import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledTableMobile = styled.div`
  display: none;

  @media(max-width: 760px) {
    display: block;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: max-content;
      border-bottom: 1px solid ${Theme.borderBlue};
      min-width: 100%;

      .header-col {
        padding: 16px 15px;
        text-align: left;
        box-sizing: border-box;

        .header-title {
          font-family: Montserrat-SemiBold;
          font-size: 14px;
          line-height: 150%;
          color: ${Theme.textBlack};
          margin: 0;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: max-content;
      min-width: 100%;
      border-bottom: 1px solid ${Theme.lightBlue};

      .body-col {
        padding: 16px 15px;
        text-align: left;
        box-sizing: border-box;

        .body-text {
          font-family: Montserrat-Regular;
          font-size: 14px;
          line-height: 150%;
          color: ${Theme.textBlack};
          margin: 0;
        }

        .text-ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .first-col {
      width: 100px;
      position: sticky;
      left: 0;
      background: ${Theme.background};
      border-right: 1px solid ${Theme.lightBlue};
    }
    .sec-col {
      width: 125px;
    }
    .third-col {
      width: 130px;
    }
    .last-col {
      width: 150px;
    }

    .table-btn {
      min-width: 131px;

      .table-btn-desktop {
        display: none;
      }
      .table-btn-mobile {
        display: inline-block;
      }
    }
  }
`;
