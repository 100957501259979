import styled from 'styled-components';
import {HTMLProps} from 'react';

interface StyledButtonProps extends HTMLProps<HTMLButtonElement> {
  flat?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  height: 52px;
  width: ${({flat}) => (flat ? '165px' : '180px')};;
  border-radius: 10px;
  background-color: ${({flat, theme}) => (flat ? 'transparent' : theme.blue)};
  font-family: ${({flat}) =>
    (flat ? 'Montserrat-Medium' : 'Montserrat-SemiBold')};
  color: ${({flat, theme}) => (flat ? theme.blue : '#fff')};
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: 2px solid #2665EB;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  span {
    display: inline-block;
  }

  :hover {
    background-color: ${({flat, theme}) =>
      flat ? theme.lightBlue : theme.hoverBlue};
  }

  :disabled {
    background-color: ${({flat}) => (!flat ? '#8793A1' : '#DDE9FF')};
    border-color: ${({flat}) => (!flat ? '#8793A1' : '#DDE9FF')};
    color: ${({flat}) => (!flat ? '#fff' : '#2665EB')};
    cursor: default;
  }

  @media(max-width: 760px) {
    height: 40px;
    font-size: 14px;
  }
`;
