export const TilesData = [
  {
    title: 'New DEX Listings',
    iconName: 'new-dex-listings',
    comingSoon: true,
    arringSoon: false,
  },
  {
    title: 'Arbitrage',
    iconName: 'arbitrage',
    comingSoon: true,
  },
  {
    title: 'Insurance',
    iconName: 'insurance',
    comingSoon: true,
  },
  {
    title: 'PancakeSwap Listings',
    iconName: 'pancakeswap',
    comingSoon: true,
    arringSoon: false,
  },
  {
    title: 'Uniswap V2 Listings',
    iconName: 'uniswapv2',
    comingSoon: true,
    arringSoon: false,
  },
  {
    title: 'New CEX Listings',
    iconName: 'new-cex-listings',
    comingSoon: true,
  },
];

export const graphData = [
  {
    text: 'Instantly discover new tokens & relevant information on them',
  },
  {
    // eslint-disable-next-line max-len
    text: 'Monitor liquidity changes, tax changes, and Twitter feeds from a single UI',
  },
  {
    text: 'Track the most popular decentralized and centralized exchanges',
  },
];

export const ToolsData = [
  {
    title: 'New DEX Listings',
    iconName: 'new-dex-listings',
    comingSoon: true,
  },
  {
    title: 'PancakeSwap Listings',
    iconName: 'pancakeswap',
    comingSoon: true,
  },
  {
    title: 'Uniswap V2 Listings',
    iconName: 'uniswapv2',
    comingSoon: true,
  },
  {
    title: 'TraderJoe Listings',
    iconName: 'traderjoe',
    comingSoon: true,
  },
  {
    title: 'Crypto Arbitrage',
    iconName: 'arbitrage',
    comingSoon: true,
  },
  {
    title: 'Trading Insurance',
    iconName: 'insurance',
    comingSoon: true,
  },
  {
    title: 'New CEX Listings',
    iconName: 'new-cex-listings',
    comingSoon: true,
  },
  {
    title: 'Telegram',
    iconName: 'telegram',
    comingSoon: false,
    url: 'https://t.me/moonwalkfinancial',
  },
  {
    title: 'Twitter',
    iconName: 'twitter',
    comingSoon: true,
  },
];
