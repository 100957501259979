import {FC, useState} from 'react';
import {slide as Menu, State} from 'react-burger-menu';
import BurgerIcon from 'assets/icons/burger-icon.svg';
import {Link} from 'react-router-dom';
import {IcomoonIcon, Button} from 'components';
import {Theme} from 'styled/Theme';
import styled from 'styled-components';
import WhitepaperPDF from 'assets/whitepaper.pdf';

export const BurgerMenu: FC = () => {
  const styles = {
    bmBurgerButton: {
      position: 'relative',
      width: '32px',
      height: '32px',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
    },
    bmMenu: {
      background: '#ffff',
      padding: '5%',
      height: 'auto',
      borderBottom: '1px solid #DDE9FF',
      boxShadow: '0px 4px 17px rgba(38, 101, 235, 0.1)',
    },
    bmItemList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bmItem: {
      display: 'inline-block',
      fontSize: '14px',
      lineHeight: '150%',
      color: '#333333',
      fontFamily: 'Montserrat-SemiBold',
      marginBottom: '20px',
      textDecoration: 'none',
    },
    bmOverlay: {
      background: 'rgba(255, 255, 255)',
    },
  };
  const [isOpenSubMenu] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleStateChange = (state: State) => {
    setMenuOpen(state.isOpen);
  };

  return (
    <Menu
      customBurgerIcon={<img src={BurgerIcon} />}
      right
      menuClassName='burger-menu'
      overlayClassName='burger-menu-overlay'
      burgerButtonClassName='burger-btn'
      bodyClassName='menu-is-open'
      noOverlay
      width={'100%'}
      styles={styles}
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <LinkWrapper>
        <Link to="/" className="link disabled">New DEX Listing</Link>
        {/* <IconButton onClick={() => setIsOpenSubMenu(!isOpenSubMenu)}>
          <IcomoonIcon
            icon="chevron-down"
            size={19}
            color={Theme.textBlack}
            className="menu-icon"
          />
        </IconButton> */}
      </LinkWrapper>
      {isOpenSubMenu && (
        <SubMenu>
          <Link to="/" className="link">Uniswap v2</Link>
          <Link to="/" className="link">PancakeSwap</Link>
          <Link to="/" className="link">TraderJoe</Link>
          <Link to="/" className="link">SpookySwap</Link>
        </SubMenu>
      )}
      <MainWrapper>
        <Link to="/" className="link disabled">Arbitrage</Link>
        <Link to="/" className="link disabled">Insurance</Link>
        <Link to="/" className="link disabled">CEX Listings</Link>
        <Link to="/roadmap"
          className="link"
          onClick={() => setMenuOpen(false)}
        >Roadmap</Link>
        <a
          className="link"
          href={WhitepaperPDF}
          target="_blank"
          rel="noreferrer"
          onClick={() => setMenuOpen(false)}
        >
          Whitepaper
        </a>
      </MainWrapper>
      <IconWrapper>
        <a target="_blank" href='https://t.me/moonwalkfinancial' className="icon-link-telegram">
          <IcomoonIcon
            icon="telegram"
            size={24}
            color={Theme.borderBlue}
          />
        </a>
        <Link to="/" className="icon-link">
          <IcomoonIcon
            icon="twitter"
            size={24}
            color={Theme.borderBlue}
          />
        </Link>
      </IconWrapper>
      <Button disabled>Connect Wallet</Button>
    </Menu>
  );
};

export default BurgerMenu;

const LinkWrapper = styled.div`
  display: flex!important;
  align-items: center;

  .link {
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    text-decoration: none;

    &.disabled {
      color: #8793A1;
      pointer-events: none;
    }
  }
`;

const MainWrapper = styled.div`
  display: flex!important;
  align-items: center;
  flex-direction: column;

  .link {
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;

    &.disabled {
      color: #8793A1;
      pointer-events: none;
    }
  }
`;

const IconWrapper = styled.div`
  .icon-link-telegram {
    margin-right: 20px;
  }
`;

// const IconButton = styled.button`
//   -webkit-appearance: none;
//   border: none;
//   padding: 0;
//   background: transparent;
//   margin-left: 4px;
// `;

const SubMenu = styled.nav`
  display: flex!important;
  flex-direction: column;
  align-items: center;

  .link {
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    text-decoration: none;
    margin-bottom: 12px;
  }

  @media(max-width: 760px) {
    .link {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
`;
