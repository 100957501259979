export const Theme = {
  lightBlue: '#DDE9FF',
  borderLightBlue: '#BED1F9',
  borderBlue: '#638DE7',
  blue: '#2665EB',
  hoverBlue: '#1A4297',
  lightGrey: '#8A8A8A',
  textGrey: '#50596D',
  textBlack: '#333333',
  red: '#EB263E',
  green: '#12AA34',
  background: '#FFFFFF',
  grey: '#EDEDED',
};
