import {FC} from 'react';
import {Col, Row} from 'react-grid-system';
import {StyledHomeTableHeader} from 'styled/components';

export const HomeTableHeader: FC = () => {
  return (
    <StyledHomeTableHeader>
      <Row className="row">
        <Col md={1} className="col">
          <p className="text-header">Listed</p>
        </Col>
        <Col md={3} className="col">
          <p className="text-header">Token</p>
        </Col>
        <Col md={2} className="col">
          <p className="text-header">Market Cap</p>
        </Col>
        {/* <Col md={1} className="col">
          <p className="text-header">Launch<br/>Price</p>
        </Col> */}
        <Col md={2} className="col">
          <p className="text-header">Current Price</p>
        </Col>
        <Col md={2} className="col">
          <p className="text-header">% Change</p>
        </Col>
        <Col md={2} className="col">
          <p className="text-header">Liquidity</p>
        </Col>
        {/* <Col md={1} className="col">
          <p className="text-header">Buy Tax</p>
        </Col> */}
        {/* <Col md={1} className="col">
          <p className="text-header">Sell Tax</p>
        </Col> */}
      </Row>
    </StyledHomeTableHeader>
  );
};
