import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledHomeDex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 160px;

  .table-block {
    text-align: left;

    h4 {
      font-family: Montserrat-Bold;
      color: ${Theme.textBlack};
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 32px;
    }

    .table-subtitle {
      font-family: Montserrat-Regular;
      color: ${Theme.textBlack};
      font-size: 18px;
      line-height: 120%;
      margin: 0px;
    }
  }

  @media(max-width: 1400px) {
    flex-direction: column;
    align-items: center;

    .table-block {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  @media(max-width: 760px) {
    margin-bottom: 80px;

    .table-block {
      width: 100%;
      h4 {
        font-size: 20px;
        margin-bottom: 16px;
      }

      .table-subtitle {
        font-size: 14px;
      }
    }
  }
`;
