import {FC} from 'react';
import {StyledHomeDexChoosing} from 'styled/components';
import {DexTabs} from './HomeDex';
import {DexTab} from './DexTab';
import GraphFigure from 'assets/figure.png';

interface HomeDexChoosingProps {
  activeDex: DexTabs;
  setActiveDex: React.Dispatch<React.SetStateAction<DexTabs>>;
}

export const HomeDexChoosing: FC<HomeDexChoosingProps> = ({
  activeDex,
  setActiveDex,
}) => {
  return (
    <StyledHomeDexChoosing>
      <div className={'image-wrapper ' + activeDex}></div>
      <DexTab activeTab={activeDex} setActiveTab={setActiveDex}>
        {DexTabs.UniSwap}
      </DexTab>
      <DexTab activeTab={activeDex} setActiveTab={setActiveDex}>
        {DexTabs.PancakeSwap}
      </DexTab>
      <DexTab activeTab={activeDex} setActiveTab={setActiveDex}>
        {DexTabs.SpookySwap}
      </DexTab>
      <DexTab activeTab={activeDex} setActiveTab={setActiveDex}>
        {DexTabs.TraderJoe}
      </DexTab>
      <img
        src={GraphFigure}
        alt="graph-figure"
        className="graph-figure-small"
      />
    </StyledHomeDexChoosing>
  );
};

export default HomeDexChoosing;
