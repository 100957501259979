export const LaunchData = [
  {
    title: 'Fair launch $CIMO',
  },
  {
    title: 'Start of Meme 3.0',
  },
  {
    title: 'Community formation',
  },
  {
    title: 'Initial marketing',
  },
  {
    title: 'Litepaper release',
  },
  {
    title: 'Whitepaper release',
  },
];

export const AcceptanceData = [
  {
    title: 'Website upgrade',
  },
  {
    title: 'NFT design show',
  },
  {
    title: 'Acceleration of marketing',
  },
  {
    title: 'Testing staking contract',
  },
  {
    title: 'Alpha testing DevTool',
  },
  {
    title: 'Coingecko & Coinmarketcap',
  },
];

export const GrowthData = [
  {
    title: 'Marketing boost',
  },
  {
    title: 'NFT presale & minting',
  },
  {
    title: 'Release staking dApp',
  },
  {
    title: 'Project partnerships',
  },
  {
    title: 'CEX listing',
  },
  {
    title: 'Beta testing DevTool',
  },
];
