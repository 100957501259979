import {StyledFaqTab} from '../styled/components';
import {FC, useState} from 'react';
import {IcomoonIcon} from './IcomoonIcon';

interface FaqItem {
  question: string;
  answer: string;
}

interface FaqTabProps {
  title: string;
  faqs: FaqItem[];
}

export const FaqTab: FC<FaqTabProps> = ({
  title,
  faqs,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <StyledFaqTab>
      <button className="faq-btn" onClick={() => {
        setIsOpen(!isOpen);
      }}>
        <span>{title}</span>
        <IcomoonIcon
          icon={isOpen ? 'minus' : 'plus'}
          size={24}
          color="#fff"
          className="faq-icon"
        />
      </button>
      {isOpen && (
        faqs.map((item) => {
          return (
            <div className="answer-wrapper">
              <p>{item.question}</p>
              <p>{item.answer}</p>
            </div>
          );
        })
      )}
    </StyledFaqTab>
  );
};
