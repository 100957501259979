import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledFooter = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 52px 80px 32px;
  background-color: ${Theme.blue};

  .footer-main-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    
    .footer-column {
      h5 {
        font-family: Montserrat-SemiBold;
        font-size: 18px;
        line-height: 150%;
        color: ${Theme.background};
        margin-bottom: 24px;
      }
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          margin-bottom: 14px;
  
          a {
            font-family: Montserrat-Regular;
            font-size: 14px;
            line-height: 130%;
            color: ${Theme.background};
            text-decoration: none;
            //TODO Update in the next version
            // &:hover {
            //   text-decoration: underline;
            // }
          }
        }
      }

      .icons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-link {
          margin-right: 10px;
          width: 40px!important;
          height: 40px!important;
          border: 1px solid white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.8;
        }
      }
    }
  
    h2 {
      font-family: Montserrat-SemiBold;
      font-size: 32px;
      line-height: 150%;
      color: ${Theme.background};
      text-align: center;
      margin-bottom: 80px;
    }
  
    .logo-wrapper {
      position: relative;

      .icons-wrapper-mobile {
        display: none;
      }
    }
  }

  .footer-info {
    .footer-info-section {
      h6 {
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        line-height: 150%;
        color: ${Theme.background};
        margin-bottom: 6px;
      }
      p {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 150%;
        color: ${Theme.background};
        margin-bottom: 20px;
      }
    }

    .footer-link-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 49px;

      .info-link {
        font-family: Montserrat-SemiBold;
        font-size: 16px;
        line-height: 150%;
        color: ${Theme.background};
        opacity: 0.8;
        text-decoration-line: underline;
      }
    }

    .terms-text {
      font-family: Montserrat-Medium;
      font-size: 12px;
      line-height: 120%;
      color: ${Theme.background};
  
      a {
        font-family: Montserrat-Medium;
        font-size: 12px;
        line-height: 120%;
        color: ${Theme.background};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      .terms-divider {
        display: inline;
      }

      .mobile-next-line {
        display: none;
      }
    }
  }

  @media(max-width: 768px) {
    padding: 40px 32px;
    .footer-main-wrapper {
      .footer-column {
        display: none;
      }

      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .icons-wrapper-mobile {
          display: block;
        }

        .icons-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
  
          .icon-link {
            margin-left: 10px;
            width: 40px!important;
            height: 40px!important;
            border: 1px solid white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.8;
          }
        }
      }
    }

    .footer-info {
      .footer-info-section {
        h6 {
          font-size: 12px;
        }
        p {
          font-size: 12px;
          margin-bottom: 16px;
        }
      }
  
      .footer-link-wrapper {
        margin-top: 20px;
        margin-bottom: 31px;
  
        .info-link {
          font-size: 14px;
        }
      }
  
      .terms-text {
        opacity: 0.8;
      }
    }
  }

  @media(max-width: 760px) {
    padding: 32px 24px;
    .footer-main-wrapper {
      .footer-column {
        display: none;
      }

      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .icons-wrapper-mobile {
          display: none;
        }
      }
    }

    .footer-info {
      .footer-info-section {
        h6 {
          font-size: 12px;
        }
        p {
          font-size: 12px;
          margin-bottom: 14px;
        }
      }
  
      .footer-link-wrapper {
        margin-top: 20px;
        margin-bottom: 25px;
  
        .info-link {
          font-size: 14px;
        }
      }
  
      .terms-text {
        text-align: center;
        .terms-divider {
          display: none;
        }

        .mobile-next-line {
          display: block;
        }

        a {
          opacity: 0.8;
        }
      }
    }  
  }
`;
