import styled from 'styled-components';
import {Theme} from 'styled/Theme';

import Pancakeswap from 'assets/icons/pancakeswap.svg';
import UniswapV2 from 'assets/icons/uniswapv2.svg';
import NewDexListings from 'assets/icons/new-dex-listings.svg';
import Arbitrage from 'assets/icons/arbitrage.svg';
import Insurance from 'assets/icons/insurance.svg';
import NewCexListings from 'assets/icons/new-cex-listings.svg';


export const StyledHomeTile = styled.button`
  box-shadow: 0px 5px 15px rgba(38, 101, 235, 0.15);
  border-radius: 20px;
  width: 200px;
  height: 180px;
  display: flex;
  -webkit-appearance: none;
  border: none;
  background: ${Theme.background};
  padding: 32px 10px 12px;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  flex: 0 0 30%;
  margin-bottom: 32px;

  :disabled {
    cursor: default;
    pointer-events: none;
  }

  :hover {
    background: ${Theme.lightBlue};

    .icon-wrapper {
      background-color: ${Theme.background};
    }
  }
  
  .icon-wrapper {
    width: 40px;
    height: 40px;
    background-color: ${Theme.lightBlue};
    border-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    flex-shrink: 0;
    transition: background-color 0.2s ease-in-out;

    &.pancakeswap {
      background-image: url(${Pancakeswap});
    }
    &.uniswapv2 {
      background-image: url(${UniswapV2});
    }
    &.new-dex-listings {
      background-image: url(${NewDexListings});
    }
    &.arbitrage {
      background-image: url(${Arbitrage});
    }
    &.insurance {
      background-image: url(${Insurance});
    }
    &.new-cex-listings {
      background-image: url(${NewCexListings});
    }
  }

  .tile-text {
    font-family: Montserrat-SemiBold;
    color: ${Theme.textBlack};
    font-size: 18px;
    line-height: 120%;
    margin: 0px;
  }

  .label {
    font-family: Montserrat-SemiBold;
    font-size: 10px;
    letter-spacing: 0.02em;
    color: ${Theme.blue};
    position: absolute;
    bottom: 12px;
    margin: 0px;
  }

  @media(max-width: 1400px) {
    height: 152px;
    margin-bottom: 16px;
    margin-right: 0px;
  }

  @media(max-width: 760px) {
    height: 136px;
    flex: 0 0 48%;
    padding: 16px 10px;
    margin-bottom: 7px;

    .tile-text {
      font-size: 14px;
      line-height: 130%;
    }

    .icon-wrapper {
      margin-bottom: 12px;
    }

    .label {
      bottom: 16px;
      font-size: 10px;
      line-height: 120%;
    }
  }
`;
