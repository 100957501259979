import {FC, useState} from 'react';
import {StyledHomeDex} from 'styled/components';
import {Button, HomeDexChoosing, HomeTable} from 'components';

export enum DexTabs {
  UniSwap = 'UniSwap',
  PancakeSwap = 'PancakeSwap',
  SpookySwap = 'SpookySwap',
  TraderJoe = 'TraderJoe',
}

export const HomeDex: FC = () => {
  const [activeDex, setActiveDex] = useState(DexTabs.UniSwap);

  return (
    <StyledHomeDex>
      <div className="table-block">
        <h4>Find your Next Gem</h4>
        <p className="table-subtitle">
          Unlock access to our industry-leading real-time
          dataset on token prices and products.
        </p>
        <HomeTable activeDex={activeDex} />
        <Button disabled>Buy $MWK</Button>
      </div>
      <HomeDexChoosing activeDex={activeDex} setActiveDex={setActiveDex} />
    </StyledHomeDex>
  );
};

export default HomeDex;
