import styled from 'styled-components';
import {Theme} from 'styled/Theme';
import Token from 'assets/icons/token-icon.svg';

export const StyledHome = styled.div`
  padding: 80px 80px 0;
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;

  h1 {
    font-family: Montserrat-Bold;
    font-size: 46px;
    line-height: 118%;
    color: ${Theme.textBlack};
    margin-bottom: 32px;

    .header-divider {
      display: none;
    }

    span {
      color: ${Theme.blue}
    }
  }

  p {
    font-family: Montserrat-Regular;
    color: ${Theme.lightGrey};
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 64px;
  }

  .top-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 160px;

    .icon-dots-left {
      position: absolute;
      z-index: -1;
      left: -76px;
      bottom: -42px;
    }
  }

  .tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-right: 33px;
  }

  .large-tile {
    min-width: 582px;
    box-shadow: 0px 5px 15px rgba(38, 101, 235, 0.15);
    border-radius: 20px;
    height: 100%;
    min-height: 392px;
    position: relative;
    background-color: ${Theme.background};

    .icon-dots-right {
      position: absolute;
      z-index: -1;
      right: -59px;
      top: -26px;
    }
  }

  .large-tile-header {
    border-bottom: 1px solid ${Theme.lightBlue};
    padding: 32px 40px;
    display: flex;
    align-items: center;

    .icon-wrapper {
      width: 40px;
      height: 40px;
      background-color: ${Theme.lightBlue};
      border-radius: 8px;
      background-position: center;
      background-repeat: no-repeat;
      flex-shrink: 0;
      background-image: url(${Token});
      margin-right: 16px;
    }

    p {
      font-family: Montserrat-SemiBold;
      color: ${Theme.textBlack};
      font-size: 18px;
      line-height: 120%;
      margin: 0px;
    }
  }

  .large-tile-body {
    padding: 32px 20px 36px 40px;
    text-align: left;

    h3 {
      font-family: Montserrat-Bold;
      color: ${Theme.textBlack};
      font-size: 22px;
      line-height: 120%;
      margin: 0px;
      margin-bottom: 24px;
    }

    p {
      font-family: Montserrat-Regular;
      color: ${Theme.textBlack};
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 16px;
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      margin-top: 32px;

      .btn-link {
        height: 52px;
        border-radius: 10px;
        font-family: Montserrat-SemiBold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        color: #fff;
        background-color: #2665EB;
        text-decoration: none;

        :hover {
          background-color: #1A4297;
        }
      }

      .large-btn {
        margin-right: 24px;
        width: 210px!important;
      }
    }
  }

  .graph-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;

    .graph-text-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      max-width: 43%;

      .graph-text-title {
        font-size: 18px;
        color: ${Theme.blue};
        margin-bottom: 16px;
      }

      h4 {
        font-family: Montserrat-Bold;
        color: ${Theme.textBlack};
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 32px;
      }

      p {
        font-size: 18px;
        margin-bottom: 32px;
      }

      .graph-text-info {
        font-family: Montserrat-Italic;
        font-size: 14px;
        line-height: 154%;
        color: ${Theme.textBlack};
        margin-bottom: 40px;
        margin-top: 32px;
      }

      .check-wrapper {
        display: flex;
        width: 99%;
        margin-bottom: 20px;

        .icon-wrapper {
          width: 32px;
          height: 32px;
          background: ${Theme.lightBlue};
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          flex-shrink: 0;
        }

        p {
          font-size: 18px;
          line-height: 150%;
          margin-bottom: 0px;
        }
      }
    }

    .graph-img-wrapper {
      width: 43%;
      background: ${Theme.background};
      box-shadow: 0px 4px 17px rgba(38, 101, 235, 0.1);
      border-radius: 50px;
      max-width: 542px;
      position: relative;
      box-sizing: border-box;

      img {
        width: 100%;
      }

      .graph-figure {
        position: absolute;
        top: -270px;
        right: -212px;
        z-index: -1;
        width: auto;
      }
    }

    .twitter-wrapper {
      position: relative;

      .icon-dots-left-large {
        position: absolute;
        left: -76px;
        bottom: 55px;
        z-index: -1;
        display: block;
      }
    }

    .twitter-text-block {
      max-width: 50%;
    }

    .tools-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;

      & > div {
        &:first-child {
          margin-right: 60px;
        }
      }
    }
  }

  @media(max-width: 1600px) {
    overflow: hidden;
  }

  @media(max-width: 1400px) {
    padding: 56px 32px 0;

    h1 {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 16px;

      .header-divider {
        display: block;
      }
    }

    p {
      font-size: 20px;
      margin-bottom: 24px;
    }

    .top-wrapper {
      flex-direction: column;
    }

    .large-tile {
      min-width: 100%;

      .icon-dots-right {
        display: none!important;
      }
    }

    .large-tile-header {
      justify-content: center;
    }

    .large-tile-body {
      text-align: center;
    }

    .btn-wrapper {
      justify-content: center;
    }

    .top-wrapper .icon-dots-left {
      bottom: 45%;
    }

    .graph-wrapper {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      .graph-img-wrapper {
        width: 100%;
        max-width: 80%;
        margin-bottom: 160px;
      }

      .graph-text-block {
        max-width: 100%;
        align-items: center;
      }
    }

    .tiles-wrapper {
      margin-right: 0px;
    }

    .tools-wrapper {
      margin-bottom: 0px;
    }

    .tools-wrapper-mobile {
      display: flex;
      flex-direction: column;
    }

    .twitter-text-block {
      margin-bottom: 160px;
    }
  }

  @media(max-width: 760px) {
    padding: 40px 24px 0;

    h1 {
      font-size: 22px;
      line-height: 150%;
      font-family: Montserrat-SemiBold;
    }

    p {
      font-size: 16px;
      line-height: 150%;
    }

    .top-wrapper {
      margin-bottom: 80px;

      .icon-dots-left {
        bottom: 34%;
        left: -69px;
      }
    }

    .large-tile {
      min-height: auto;
    }

    .large-tile-header {
      padding: 20px;

      p {
        font-size: 14px;
        line-height: 120%;
      }
    }

    .large-tile-body {
      padding: 20px;
      text-align: left;

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
        line-height: 150%;
      }

      .btn-wrapper {
        margin-top: 18px;

        .btn-link {
          font-family: Montserrat-Medium;
          font-size: 14px;
          height: 40px;
        }
      }
    }

    .graph-wrapper {
      margin-bottom: 80px;

      .tools-wrapper {
        & > div {
          &:first-child {
            margin-right: 0px;
          }
        }
      }
    }

    .graph-wrapper .graph-img-wrapper {
      max-width: 100%;
      margin-bottom: 80px;
    }

    .graph-wrapper .graph-img-wrapper .graph-figure {
      top: -155px;
      right: -112px;
      width: 600px;
    }

    .graph-wrapper .graph-text-block {
      align-items: flex-start;

      .graph-text-title {
        font-size: 14px;
        line-height: 120%;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 16px;
      }

      p {
        font-size: 14px;
        line-height: 150%;
      }

      .check-wrapper {
        width: 100%;
        align-items: center;

        .icon-wrapper {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        p {
          font-size: 14px;
        }
      }

      .graph-text-info {
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 30px;
      }
    }

    .graph-wrapper .tools-wrapper {
      flex-direction: column;
    }

    .twitter-text-block {
      margin-bottom: 80px;
    }

    .tools-wrapper-mobile {
      flex-direction: column;
      background: #FFFFFF;
      box-shadow: 0px 4px 17px rgb(38 101 235 / 10%);
      border-radius: 50px;
      width: 100%;
      padding: 32px;
      box-sizing: border-box;
    }

    .twitter-wrapper {
      width: 100%;
      & > img {
        width: 100%;
      }

      .icon-dots-left-large {
        display: none!important;
      }
    }

    .large-tile-body .btn-wrapper .large-btn {
      margin-right: 10px;
    }
  }
`;
