import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledFaq = styled.div`
  background-color: ${Theme.blue};
  padding: 80px;
  border-radius: 50px 50px 0px 0px;
  border-bottom: 1px solid ${Theme.lightBlue};
  display: none;

  h2 {
    font-family: Montserrat-SemiBold;
    font-size: 32px;
    line-height: 150%;
    color: ${Theme.background};
    text-align: center;
    margin-bottom: 80px;
  }

  .main-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    .img-wrapper {
      width: 432px;
      height: 464px;
      background-color: ${Theme.lightBlue};
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      display: flex;
  
      & > img {
        width: 65%;
      }
    }
  }

  @media(max-width: 1400px) {
    padding: 28px 32px 65px;

    h2 {
      margin-bottom: 64px;
    }
    .main-wrapper {
      align-items: center;

      .img-wrapper {
        width: 260px;
        height: 352px;
        margin-right: 32px;
      }
    }
  }

  @media(max-width: 760px) {
    padding: 28px 24px 12px;
    border-bottom: none;
    
    h2 {
      font-size: 24px;
      margin-bottom: 40px;
    }

    .main-wrapper {
      width: 100%;
      
      .img-wrapper {
        display: none;
      }

      .faq-wrapper {
        width: 100%;
      }
    }
  }
`;
