/* eslint-disable max-len */
import {FC} from 'react';
import {StyledPolicyPage} from 'styled/components';
import {FinancialDisclaimerData} from '../data/PolicyData';
import {PolicyItem} from 'components';

export const FinancialDisclaimer: FC = () => {
  return (
    <StyledPolicyPage>
      <h1>Financial Disclaimer</h1>
      {FinancialDisclaimerData.map((item, i) => {
        return (
          <PolicyItem title={item.title} text={item.text} index={i} key={item.title}/>
        );
      })}
    </StyledPolicyPage>
  );
};

export default FinancialDisclaimer;
