/* eslint-disable require-jsdoc */
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import './App.css';
import {
  Header,
  Home,
  Faq,
  Footer,
  Roadmap,
  FinancialDisclaimer,
  TermsConditions,
  TermsOfTokenSaleUse,
  PrivacyPolicy,
} from 'components';
import {GlobalStyle} from 'styled/Global';
import {Theme} from 'styled/Theme';
import {ScrollToTop} from 'helpers/scrollToTop';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <GlobalStyle />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route
            path="/financial-disclaimer" element={<FinancialDisclaimer />} />
          <Route
            path="/terms-conditions" element={<TermsConditions />} />
          <Route
            path="/terms-of-token-sale-use" element={<TermsOfTokenSaleUse />} />
          <Route
            path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Faq />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
