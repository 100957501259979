import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledHeader = styled.div`
  width: 100%;
  height: 112px;
  box-sizing: border-box;
  box-shadow: 0px 4px 17px rgba(38, 101, 235, 0.1);
  background-color: ${Theme.background};
  border-bottom: 1px solid rgba(38, 101, 235, 0.3);
  display: flex;
  align-items: center;
  padding: 0 80px;

  .header-main-wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .mobile-menu {
      display: none;
    }

    .desktop-btn-wrapper {
      display: flex;
      position: relative;

      .desktop-btn {
        display: flex;
      }

      .label-link {
        position: absolute;
        cursor: default;
        font-family: Roboto-Medium;
        font-size: 8px;
        letter-spacing: 0.04em;
        top: -13px;
        left: 50%;
        transform: translate(-50%, 0);
        white-space: nowrap;
        color: ${Theme.blue};
      }
    }
  
    .desktop-menu {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      .link {
        text-decoration: none;
        color: ${Theme.textBlack};
        position: relative;
  
        &.arbitrage,
        &.insurance,
        &.cexListings {
          cursor: default;
          pointer-events: none;
        }
      }
  
      li {
        list-style: none;
        margin: 0 22px;
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        line-height: 17px;
        &.newTokenListing {
          position: relative;
          cursor: pointer;
          //TODO Update in next version
          // :hover {
          //   > div {
          //     display: block;
          //   }
          //   .menu-icon {
          //     transform: rotate(0deg);
          //   }
          // }
        }
        &.active,
        :hover {
          opacity: 1;
        }
        a {
          text-decoration: none;
          color: #fff;
        }
        .menu-icon {
          transform: rotate(-180deg);
          transition: transform 0.2s ease-in-out;
        }
  
        .internal-link {
          background: ${Theme.background};
          border: 1px solid ${Theme.borderLightBlue};
          box-shadow: 0px 4px 17px rgba(38, 101, 235, 0.1);
          position: absolute;
          display: none;
          z-index: 1;
          opacity: 1;
          width: 100%;
          a {
            :first-child {
              > li {
                padding-top: 16px;
              }
            }
            :last-child {
              > li {
                padding-bottom: 16px;
              }
            }
          }
          li {
            padding: 0px;
            margin: 0px;
            font-family: Montserrat-Regular;
            color: ${Theme.textGrey};
            padding: 6px 12px;
            transition: background 0.2s ease-in-out;
            :hover {
              background: ${Theme.lightBlue};
            }
          }
        }
      }
    }
  
    .label-link {
      position: absolute;
      cursor: default;
      font-family: Roboto-Medium;
      font-size: 8px;
      letter-spacing: 0.04em;
      top: -65%;
      left: 50%;
      transform: translate(-50%, 0);
      white-space: nowrap;
      color: ${Theme.blue};
    }
  
    .burger-btn {
      display: none;
    }
  }

  @media(max-width: 1400px) {
    padding: 0 40px;

    .header-main-wrapper .desktop-menu {
      li {
        margin: 0 10px;
      }
    }
  }

  @media(max-width: 1200px) {
    .header-main-wrapper {
      .desktop-menu, .desktop-btn-wrapper {
        display: none;
      }
      .mobile-menu {
        display: block;
      }
      .burger-btn {
        display: block;
        margin: 35px 0;
      }
    }
  }

  @media(max-width: 1000px) {
    padding: 0 32px;
  }

  @media(max-width: 760px) {
    height: 88px;
    .header-main-wrapper {
      .burger-btn {
        margin: 20px 0;
      }
    }
  }
`;
