import styled from 'styled-components';
import {Theme} from 'styled/Theme';
import Uniswap from 'assets/icons/uniswapv2.svg';

export const StyledRoadmap = styled.div`
  padding: 80px 80px 104px;
  text-align: center;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  h1 {
    font-family: Montserrat-Bold;
    font-size: 46px;
    line-height: 118%;
    color: ${Theme.textBlack};
    margin-bottom: 56px;
  }

  .image-wrapper {
    height: 450px;
    width: 100%;
    background: ${Theme.lightBlue};
    border-radius: 50px;
    background-image: url(${Uniswap});
    background-size: 112px;
    margin-bottom: 32px;
  }

  .info-text {
    font-family: Montserrat-Regular;
    color: ${Theme.textBlack};
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 56px;

    .info-text-divider {
      display: inline-block;
    }
  }

  h2 {
    font-family: Montserrat-Bold;
    font-size: 32px;
    line-height: 120%;
    color: ${Theme.textBlack};
    margin-bottom: 40px;
  }

  h3 {
    font-family: Montserrat-Bold;
    font-size: 24px;
    line-height: 120%;
    color: ${Theme.textBlack};
    margin-bottom: 32px;
  }

  .focus-wrapper {
    position: relative;
    margin-bottom: 56px;

    .icon-dots {
      position: absolute;
      z-index: -1;
    }

    .icon-dots-right {
      top: -30px;
      right: -108px;
    }

    .icon-dots-left {
      top: 43px;
      left: -93px;
    }

    .graph-figure {
      top: 200px;
      right: -200px;
      width: 350px;
    }

    .section-wrapper {
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: left;

      & > div:first-child {
        padding-right: 42px;
      }

      & > div:last-child {
        padding-left: 42px;
        padding-top: 17px;
        text-align: left;
        border-left: 2px solid ${Theme.blue};
        position: relative;

        &::before {
          content: '';
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: ${Theme.lightBlue};
          border: 4px solid ${Theme.borderBlue};
          display: block;
          position: absolute;
          top: 21px;
          left: -11px;
        }
      }

      .section-items-wrapper {
        display: flex;
        justify-content: space-between;
    
        .check-wrapper {
          display: flex;
          width: 99%;
          margin-bottom: 20px;
    
          .icon-wrapper {
            width: 32px;
            height: 32px;
            background: ${Theme.lightBlue};
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            flex-shrink: 0;
          }
    
          p {
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 0px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .icon-dots-bottom {
    z-index: -1;
    display: block!important;
    position: absolute;
    bottom: -50px;
    left: 314px;
  }

  @media(max-width: 1600px) {
    overflow: hidden;
  }

  @media(max-width: 1400px) {
    padding: 56px 32px 24px;
  }

  @media(max-width: 1260px) {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 32px;
    }

    .info-text {
      font-size: 20px;
      .info-text-divider {
        display: none;
      }
    }

    .focus-wrapper .section-wrapper .section-items-wrapper {
      flex-direction: column;
    }

    .icon-dots-bottom {
      display: none!important;
    }
  }

  @media(max-width: 760px) {
    padding: 40px 24px 0;

    h1 {
      font-size: 22px;
      line-height: 150%;
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
      line-height: 150%;
    }

    .focus-wrapper .section-wrapper {
      display: flex;
    }

    .focus-wrapper .section-wrapper > div:first-child {
      padding-right: 8px;
      padding-top: 17px;
    }

    .focus-wrapper .section-wrapper.section-wrapper-second {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-left: 8px;
    }

    .focus-wrapper .section-wrapper .section-items-wrapper .check-wrapper {
      width: 100%;
      align-items: center;

      .icon-wrapper {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .icon-check {
        width: 16px;
      }

      p {
        font-size: 14px;
      }
    }

    .focus-wrapper .icon-dots-right {
      display: none!important;
    }

    .focus-wrapper .icon-dots-left {
      top: 135px;
      left: -158px;
    }

    .icon-dots-bottom {
      display: block!important;
      bottom: -100px;
      left: -44px;
    }
  }
`;
