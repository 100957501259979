import {StyledHomeTable} from 'styled/components';
import {FC} from 'react';
import {IPair} from 'models/IPair';
import {HomeTableItem, Button, TableMobile, HomeTableHeader} from 'components';
import {Rings} from 'react-loader-spinner';
import {useUniswapPairsQuery} from 'services/uniswap';
import {usePancakeSwapPairsQuery} from 'services/pancakeswap';
import {useSpookySwapPairsQuery} from 'services/spookyswap';
import {useTraderjoePairsQuery} from 'services/traderjoe';
import {DexTabs} from './HomeDex';


interface HomeTableProps {
  activeDex: DexTabs;
}

export const HomeTable: FC<HomeTableProps> = ({activeDex}) => {
  const {data: uniswapData, isLoading: uniswapLoading} =
    useUniswapPairsQuery(
        {page: 1},
        {skip: activeDex !== DexTabs.UniSwap},
    );
  const {data: pancakeSwapData, isLoading: pancakeSwapLoading} =
    usePancakeSwapPairsQuery(
        {page: 1},
        {skip: activeDex !== DexTabs.PancakeSwap},
    );
  const {data: spookySwapData, isLoading: spookySwapLoading} =
    useSpookySwapPairsQuery(
        {page: 1},
        {skip: activeDex !== DexTabs.SpookySwap},
    );
  const {data: traiderjoeData, isLoading: traiderjoeLoading} =
    useTraderjoePairsQuery(
        {page: 1},
        {skip: activeDex !== DexTabs.TraderJoe},
    );

  const isLoading = uniswapLoading ||
    pancakeSwapLoading ||
    spookySwapLoading ||
    traiderjoeLoading;


  let tableData: IPair[] | undefined = [];

  switch (activeDex) {
    case DexTabs.UniSwap:
      tableData = uniswapData?.pairs;
      break;
    case DexTabs.PancakeSwap:
      tableData = pancakeSwapData?.pairs;
      break;
    case DexTabs.SpookySwap:
      tableData = spookySwapData?.pairs;
      break;
    case DexTabs.TraderJoe:
      tableData = traiderjoeData?.pairs;
      break;
  }

  return (
    <StyledHomeTable>
      <div className="table-desktop">
        <HomeTableHeader />
        {tableData?.length && !isLoading && (
          tableData.map((pair) => (
            <HomeTableItem item={pair} key={pair.pairAddress} />
          ))
        )}
      </div>
      {tableData?.length && !isLoading && (
        <TableMobile items={tableData} />
      )}
      {isLoading && (
        <div className="loader-wrapper">
          <Rings color="#2665EB" height={80} width={80} />
        </div>
      )}
      <Button className="table-btn" flat disabled>
        <span className="table-btn-desktop">New {activeDex} Listing</span>
        <span className="table-btn-mobile">{activeDex}</span>
      </Button>
    </StyledHomeTable>
  );
};
