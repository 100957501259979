import styled from 'styled-components';
import {Theme} from 'styled/Theme';

import Pancakeswap from 'assets/icons/pancakeswap.svg';
import UniswapV2 from 'assets/icons/uniswapv2.svg';
import NewDexListings from 'assets/icons/new-dex-listings.svg';
import Arbitrage from 'assets/icons/arbitrage.svg';
import Insurance from 'assets/icons/insurance.svg';
import NewCexListings from 'assets/icons/new-cex-listings.svg';
import TraderJoe from 'assets/icons/traderjoe.svg';
import Telegram from 'assets/icons/telegram.svg';
import Twitter from 'assets/icons/twitter.svg';


export const StyledHomeTool = styled.button`
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  border: none;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  cursor: pointer;
  background: none;
  padding: 0;

  :disabled {
    cursor: default;
    pointer-events: none;
  }

  :hover {
    .tool-text {
      color: ${Theme.blue};
    }
  }
  
  .icon-wrapper {
    width: 40px;
    height: 40px;
    background-color: ${Theme.lightBlue};
    border-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 16px;
    flex-shrink: 0;

    &.pancakeswap {
      background-image: url(${Pancakeswap});
    }
    &.uniswapv2 {
      background-image: url(${UniswapV2});
    }
    &.new-dex-listings {
      background-image: url(${NewDexListings});
    }
    &.arbitrage {
      background-image: url(${Arbitrage});
    }
    &.insurance {
      background-image: url(${Insurance});
    }
    &.new-cex-listings {
      background-image: url(${NewCexListings});
    }
    &.traderjoe {
      background-image: url(${TraderJoe});
    }
    &.telegram {
      background-image: url(${Telegram});
    }
    &.twitter {
      background-image: url(${Twitter});
    }
  }

  .tool-text {
    font-family: Montserrat-Medium;
    color: ${Theme.textBlack};
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 0px!important;
    text-decoration-line: underline;
    transition: color 0.2s ease-in-out;
  }

  @media(max-width: 760px) {
    margin-bottom: 16px;
  }
`;
