import {StyledDexTab} from '../styled/components';
import {Dispatch, FC, SetStateAction} from 'react';
import {DexTabs} from './HomeDex';

interface DexTabProps {
  activeTab: DexTabs;
  setActiveTab: Dispatch<SetStateAction<DexTabs>>;
  children: string;
}

export const DexTab: FC<DexTabProps> = ({
  activeTab,
  setActiveTab,
  children,
}) => {
  return (
    <StyledDexTab
      className={activeTab === children ? 'active-tab' : ''}
      onClick={() => setActiveTab(children as DexTabs)}
    >
      {children}
    </StyledDexTab>
  );
};
