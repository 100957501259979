import {FC, HTMLProps} from 'react';
import {StyledHomeTile} from 'styled/components';

interface HomeTileProps extends HTMLProps<HTMLButtonElement> {
  title: string;
  iconName: string;
  comingSoon?: boolean;
  arringSoon?: boolean;
}

export const HomeTile: FC<HomeTileProps> = ({
  title,
  iconName,
  comingSoon,
  arringSoon,
}) => {
  return (
    <StyledHomeTile disabled={comingSoon || arringSoon}>
      <div className={'icon-wrapper ' + iconName}></div>
      <p className="tile-text">{title}</p>
      {comingSoon && (
        <p className='label'>COMING SOON</p>
      )}
      {arringSoon && (
        <p className='label'>SEP 15 ARRIVING</p>
      )}
    </StyledHomeTile>
  );
};

export default HomeTile;
