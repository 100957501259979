import styled from 'styled-components';
import {Theme} from 'styled/Theme';

export const StyledHomeTable = styled.div`
  background: ${Theme.background};
  border: 1px solid ${Theme.borderBlue};
  box-shadow: 0px 10px 25px rgba(38, 101, 235, 0.15);
  border-radius: 50px;
  min-width: 816px;
  width: calc(100% - 32px);
  height: 520px;
  position: relative;
  overflow: hidden;
  margin: 52px 32px 52px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;

  .table-desktop {
    display: block;
    text-align: left;
  }

  .table-btn {
    align-self: center;
    width: max-content;
    min-width: 227px;
    position: absolute;
    z-index: 1;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);

    .table-btn-desktop {
      display: inline-block;
    }
    .table-btn-mobile {
      display: none;
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 251px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.96) 73.44%, #FFFFFF 100%);\
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }

  @media(max-width: 1400px) {
    margin: 52px 0;
    min-width: 100%;
    width: 100%;
  }

  @media(max-width: 760px) {
    border: 1px solid ${Theme.borderBlue};
    border-radius: 10px;
    padding: 0px;
    min-width: 100%;
    width: 100%;
    height: 466px;

    .table-desktop {
      display: none;
    }

    .table-btn {
      min-width: 131px;

      .table-btn-desktop {
        display: none;
      }
      .table-btn-mobile {
        display: inline-block;
      }
    }
  }
`;
