import {FC, HTMLProps} from 'react';
import {StyledHomeTool} from 'styled/components';

interface HomeToolProps extends HTMLProps<HTMLButtonElement> {
  title: string;
  iconName: string;
  comingSoon?: boolean;
  url?: string;
}

export const HomeTool: FC<HomeToolProps> = ({
  title,
  iconName,
  comingSoon,
  url,
}) => {
  return (
    <StyledHomeTool
      disabled={comingSoon}
      onClick={() => window.open(url, '_blank')}
    >
      <div className={'icon-wrapper ' + iconName}></div>
      <p className="tool-text">{title}</p>
    </StyledHomeTool>
  );
};

export default HomeTool;
