import {createApi} from '@reduxjs/toolkit/query/react';
import {IPair} from 'models/IPair';
import {baseQuery} from './baseQuery';
import io from 'socket.io-client';
import {IPairsListResponse} from 'models/IPairsListResponse';

export const spookyswapAPI = createApi({
  reducerPath: 'spookySwap',
  tagTypes: ['SpookySwapPairs'],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    spookySwapPairs: builder.query<IPairsListResponse, { page: number }>({
      query: ({page = 1}) => ({
        url: `/spookyswap/pairs?page=${page}&limit=10`,
        method: 'GET',
      }),
      async onCacheEntryAdded(
          arg,
          {updateCachedData, cacheDataLoaded, cacheEntryRemoved},
      ) {
        const socket = io(`${process.env.REACT_APP_BASE_URL}spookyswap`, {
          transports: ['websocket'],
        });
        const onFocus = () => {
          if (!socket.connected) {
            socket.connect();
          }
        };
        try {
          await cacheDataLoaded;

          const listenerToNewPair = (pair: IPair) => {
            updateCachedData((draft) => {
              draft.pairs.unshift(pair);
            });
          };
          const listenerUpdatedInfo = (pair: IPair) => {
            updateCachedData((draft) => {
              const updatedArray = draft.pairs.map((item) =>
                item.pairAddress === pair.pairAddress ? {...pair} : item,
              );
              Object.assign(draft, updatedArray);
            });
          };
          const listenerSyncEvent = (pair: IPair) => {
            updateCachedData((draft) => {
              const updatedArray = draft.pairs.map((item) =>
                item.pairAddress === pair.pairAddress ?
                  {...item, ...pair} :
                  item,
              );
              Object.assign(draft, updatedArray);
            });
          };

          window.addEventListener('focus', onFocus);
          socket.on('spookyswapNewPairsInfo', listenerToNewPair);
          socket.on('spookyswapUpdatedPairsInfo', listenerUpdatedInfo);
          socket.on('spookyswapHandleSyncEvent', listenerSyncEvent);
        } catch (e) {
          console.error(e);
        }
        await cacheEntryRemoved;
        window.removeEventListener('focus', onFocus);
        socket.close();
      },
      providesTags: (result) =>
        result ?
          [
            ...result.pairs.map(({pairAddress}) => ({
              type: 'SpookySwapPairs' as const,
              pairAddress,
            })),
            {type: 'SpookySwapPairs', id: 'PARTIAL-LIST'},
          ] :
          [{type: 'SpookySwapPairs', id: 'PARTIAL-LIST'}],
    }),
  }),
});

export const {
  useSpookySwapPairsQuery,
} = spookyswapAPI;
